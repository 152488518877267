import React, { useEffect } from 'react'
import logos from '../assest/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogo } from '../rtk/slices/logo-slice';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Footer () {
    const dispatch = useDispatch();
    const logo = useSelector((state) => state.logo.logo);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18next.on('languageChanged', function (lng) {
            // تحديث اتجاه الصفحة بناءً على اللغة الحالية
            document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        });

        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])

    useEffect(() => {
        // fetch show
        dispatch(fetchLogo());
    }, [])
    return (
        <footer>
            <div className="container">
                <div className="top-content">
                    <div className="img">
                        <img style={{ width: '150px' }} src={logo.length > 0 ? `${process.env.REACT_APP_PROJECTS_API}assest/imgs/${logo[0].logo}` : logos} alt="" />
                    </div>

                    <div className="box bottom">
                        <h3>{i18n.language == 'ar' ? 'تواصل مع' : 'Contact Me'}</h3>

                        <div className="icons">
                            <a target='_blank' href="https://www.linkedin.com/in/mohamed-abbady?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i class="fa fa-linkedin"></i></a>
                            <a target='_blank' href="https://www.instagram.com/abbadygram/profilecard/?igsh=MXR5aWxiampxdzYwbQ=="><i class="fa fa-instagram"></i></a>
                            <a target='_blank' href="https://api.whatsapp.com/send/?phone=201065483898"><i class="fa fa-whatsapp"></i></a>
                            <a target='_blank' href="https://www.facebook.com/share/19fzYGGdt1/"><i class="fa fa-facebook"></i></a>
                        </div>
                    </div>
                </div>

                <div className="center-content">

                </div>

                <div className="bottom-content">
                    <div className="copyright">
                        <p>©{i18n.language == 'ar' ? 'حقوق النشر' : 'Copyright'} 2024 <a href="#">The Content Pro</a> | <span> {i18n.language == 'ar' ? 'تم البرمجة بواسطة' : 'Design By'} <a target="_blank" href="https://www.facebook.com/mohamedaraby.mando.01012648537">Mohamed Araby</a></span></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
