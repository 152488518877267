import React, { useEffect } from 'react'
import customer1 from '../assest/customer1.png'
import customer2 from '../assest/customer2.png'
import customer3 from '../assest/customer3.png'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
// import required modules
import { Autoplay } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { fetchCustomers } from '../rtk/slices/customers-slice';


function Customers () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18next.on('languageChanged', function (lng) {
            // تحديث اتجاه الصفحة بناءً على اللغة الحالية
            document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        });

        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])

    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const slidesPerView = window.innerWidth >= 768 ? 5 : 3;


    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers.customers);

    useEffect(() => {
        // fetch show
        dispatch(fetchCustomers());
    }, [])
    return (
        <section className='customers'>
            <div className="container">
                <h2 className="title">
                    {i18n.language == 'ar' ? <p>عملا<span style={{ color: 'var(--five-color)' }}>ئي</span></p> : <p>My <span style={{ color: 'var(--five-color)' }}>Clients</span></p>}
                </h2>
                <div className="customer">
                    <h4 className='description'>{i18n.language == 'ar' ? 'ساهمت في تطوير العديد من العلامات التجارية وتحقيق نمو رقمي حقيقي... الآن جاء دورك!' : 'I’ve helped numerous brands achieve genuine digital growth—now it’s your turn!'}</h4>
                    <div className="cards">

                        <Swiper
                            spaceBetween={30}
                            initialSlide={3}
                            slidesPerView={slidesPerView}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Autoplay]}
                            className="mySwiper"
                        >
                            {customers?.map((customer, index) => {
                                return (
                                    <SwiperSlide>
                                        <div className="card-swiper">
                                            <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${customer.img}`} alt={`customer ${index}`} />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Customers
