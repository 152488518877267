import React, { useEffect } from 'react'
import landing from "../assest/landing.png"
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Aos from 'aos';
import "aos/dist/aos.css"

function Home () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        Aos.init();

        i18next.on('languageChanged', function (lng) {
            // تحديث اتجاه الصفحة بناءً على اللغة الحالية
            document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        });

        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])

    return (
        <div className='home' id='home'>
            {/* <div className="bg">
                <img src={bg} alt="" />
            </div> */}
            <div className="container">
                <div className="content">
                    <div className="box-text">
                        {i18n.language == 'ar' ? <h2 data-aos="fade-up" data-aos-duration="1000"> حوّل علامتك التجارية إلى مغناطيس يجذب عملاءك المثاليين...خلال أقل من <span> 90 يومًا</span> !</h2> : <h2 data-aos="fade-up" data-aos-duration="1500" style={{ fontSize: '2.4rem', lineHeight: '1.2' }}> Turn your brand into a magnet that attracts your ideal customers... in less than <span>90 Days</span> !</h2>}

                        {i18n.language == 'ar' ? <p data-aos="fade-up" data-aos-duration="1200">لا تجعل النجاح مجرد حلم... اجعله حقيقة ملموسة!
                            مع أكثر من أربع سنوات من الخبرة العملية، مشروعي يعتمد على التنفيذ الواقعي الذي يُحدث تحولًا حقيقيًا في الأعمال:
                            <span>استراتيجيات تسويقية تجذب الجمهور المستهدف بدقة.</span>
                            <span>محتوى يبني ثقة عملائك ويُضاعف مبيعاتك. </span>
                            <span>دعم مستمر يضمن تفوقك على المنافسين.</span>
                            ابدأ الآن، ولا تؤجل نجاحك!</p> :
                            <p data-aos="fade-up" data-aos-duration="12000">
                                Don’t let success remain a dream—make it a tangible reality!
                                With over 4 years of hands-on experience, I provide practical solutions that drive real transformation for businesses:
                                <span>Marketing strategies that precisely target your ideal audience.</span>
                                <span>Content that builds trust with your customers and doubles your sales.</span>
                                <span>Ongoing support to ensure you stay ahead of the competition.</span>
                                Start today—your success can’t wait

                            </p>}

                        <button data-aos="fade-up" data-aos-duration="1500" className='btn'><a target='_blank' href="https://calendly.com/mohamed-abbady" className='btnTextHome'>{i18n.language == 'ar' ? 'جاهز لبدء رحلتك نحو النجاح؟ تواصل معي الآن!' : 'Ready to kickstart your journey to success? Contact me now'}!</a></button>
                    </div>
                    <div className="box-image">
                        <img data-aos="zoom-in-right" src={landing} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
