import i18next from 'i18next';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServices } from '../rtk/slices/services-slice';
import Swal from 'sweetalert2';

export default function Contact () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18next.on('languageChanged', function (lng) {
            // تحديث اتجاه الصفحة بناءً على اللغة الحالية
            document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        });

        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])

    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);

    const services_en = services.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "img_service": ser_en.img_service,
                "keyword": ser_en.keyword_en,
                "whats_service": ser_en.whats_service_en
            })
        }
        return ser_en;
    })


    useEffect(() => {
        // fetch show
        dispatch(fetchServices());
    }, [])

    // sent comment
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [option, setOption] = useState('');
    const [loading, setLoading] = useState(false); // حالة التحميل

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(option);


        const reviewData = { name, phone, email, message, option };

        setLoading(true); // تفعيل حالة التحميل

        try {
            const response = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/submit_message.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reviewData),
            });

            const result = await response.json();

            if (response.ok) {
                Swal.fire({
                    title: i18n.language == 'ar' ? 'تم إرسال الرسالة!' : 'The message has been sent!',
                    text: i18n.language == 'ar' ? 'سوف يتم الرد عليك في أقرب وقت!' : 'You will be answered as soon as possible!',
                    icon: 'success',
                    background: '#f0f8ff',
                    color: '#273773',
                    iconColor: '#273773',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            } else {
                Swal.fire({
                    title: i18n.language == 'ar' ? 'خطأ!' : 'Error!',
                    text: i18n.language == 'ar' ? 'حدث خطأ أثناء إرسال الرسالة. حاول مرة أخرى' : 'An error occurred while sending the message. Try again',
                    icon: 'error',
                    background: '#f0f8ff',
                    color: '#FE6464',
                    iconColor: '#FE6464',
                    timer: 3000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
            }
        } catch (error) {
            Swal.fire({
                title: i18n.language == 'ar' ? 'خطأ!' : 'Error!',
                text: i18n.language == 'ar' ? 'حدث خطأ أثناء إرسال الرسالة. حاول مرة أخرى' : 'An error occurred while sending the message. Try again',
                icon: 'error',
                background: '#f0f8ff',
                color: '#FE6464',
                iconColor: '#FE6464',
                timer: 3000,
                timerProgressBar: true,
                showConfirmButton: false,
            });
        } finally {
            setLoading(false); // تعطيل حالة التحميل
        }

        setName('');
        setPhone('');
        setEmail('');
        setMessage('');
        setOption('');
    };


    return (
        <section className='contact'>
            <div className="container">
                <div className="content">
                    <div className="box-text" data-aos="flip-up" data-aos-duration="1200">
                        {i18n.language == "ar" ? <h2> تواصل  <span> معي</span></h2> : <h2> Contact <span> Me</span></h2>}

                        <div className="form">
                            <form onSubmit={handleSubmit} method="post">
                                <div className="inputGroup">
                                    <label htmlFor="name">{i18n.language == 'ar' ? 'الاسم' : 'Name'}*</label>
                                    <input value={name} onChange={(e) => setName(e.target.value)} id='name' type="text" placeholder={i18n.language == 'ar' ? 'الاسم' : 'Your Name'} required />
                                </div>
                                <div className="inputGroup">
                                    <label htmlFor="email">{i18n.language == 'ar' ? 'البريد الإلكتروني' : 'Email'}*</label>
                                    <input style={{ textTransform: 'lowercase' }} value={email} onChange={(e) => setEmail(e.target.value)} id='email' type="email" placeholder={i18n.language == 'ar' ? 'بريدك الإلكتروني' : 'Email'} required />
                                </div>
                                <div className="inputGroup">
                                    <label htmlFor="phone">{i18n.language == 'ar' ? 'رقم الهاتف' : 'Phone Number'}*</label>
                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} id='phone' type="number" placeholder={i18n.language == 'ar' ? 'رقم الهاتف' : 'Phone Number'} required />
                                </div>
                                <div className="inputGroup">
                                    <label htmlFor="message">{i18n.language == 'ar' ? 'الرسالة' : 'Message'}*</label>
                                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} style={{ resize: 'none' }} cols={30} rows={10} id='message' type="text" placeholder={i18n.language == 'ar' ? 'الرسالة' : 'Message'} required ></textarea>
                                </div>

                                <div className="options">
                                    <h4>{i18n.language == 'ar' ? 'لماذا تتصل بنا ؟' : 'Why are you contacting us؟'} *</h4>

                                    {services_en?.map((service, index) => {
                                        return (
                                            <div className="groupOption">
                                                <input checked={service.keyword == option && true} value={service.keyword} onChange={(e) => setOption(e.target.value)} type="radio" id={index} name="option" required />
                                                <label htmlFor={index}>{service.keyword}</label>
                                            </div>
                                        )
                                    })}
                                </div>

                                <button style={{ fontSize: '.8rem' }} className="btn" type='submit'>{i18n.language == 'ar' ? 'ابدأ اليوم واحصل على استشارة مجانية' : 'Start Today – Get Your Free Consultation'}</button>
                            </form>
                            <span >{i18n.language == 'ar' ? 'أو' : 'Or'}</span>

                            <div style={{ marginTop: '.5rem' }} className="icons">
                                <a target='_blank' href="https://www.linkedin.com/in/mohamed-abbady?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><i class="fa fa-linkedin"></i></a>
                                <a target='_blank' href="https://www.instagram.com/abbadygram/profilecard/?igsh=MXR5aWxiampxdzYwbQ=="><i class="fa fa-instagram"></i></a>
                                <a target='_blank' href="https://api.whatsapp.com/send/?phone=201065483898"><i class="fa fa-whatsapp"></i></a>
                                <a target='_blank' href="https://www.facebook.com/share/19fzYGGdt1/"><i class="fa fa-facebook"></i></a>
                            </div>
                        </div>
                    </div>

                    <div className="box-image">
                        <img style={{ width: '100%', maxWidth: '30rem' }} src={require('../assest/contact.png')} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
