import i18next from 'i18next';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export default function Benefit () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18next.on('languageChanged', function (lng) {
            // تحديث اتجاه الصفحة بناءً على اللغة الحالية
            document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        });

        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])

    return (
        <section className='benefit'>
            <div className="container">
                <h2 className="title" data-aos="fade-up" data-aos-duration="1200">
                    {i18n.language == 'ar' ? <p> ما الفوائد التي   <span style={{ color: 'var(--five-color)' }}>ستجنيها</span>؟</p> : <p> What <span style={{ color: 'var(--five-color)' }}>Benefit</span> Will You Gain?</p>}
                </h2>

                <div className="cards">
                    <div className="card" data-aos="zoom-out-up" data-aos-duration="1500">
                        <div className="icon">
                            <img style={{ width: '64px' }} src={require('../assest/benefit1.png')} alt="" />
                        </div>
                        <h3>{i18n.language == 'ar' ? 'تحقيق تفوق على المنافسين' : 'Stay ahead of your competition'}</h3>
                    </div>

                    <div className="card" data-aos="zoom-out-up" data-aos-duration="1500">
                        <div className="icon">
                            <img style={{ width: '64px' }} src={require('../assest/benefit2.png')} alt="" />
                        </div>
                        <h3>{i18n.language == 'ar' ? 'زيادة ملحوظة في المبيعات' : 'Build strong trust with your audience'}</h3>
                    </div>

                    <div className="card" data-aos="zoom-out-up" data-aos-duration="1500">
                        <div className="icon">
                            <img style={{ width: '64px' }} src={require('../assest/benefit3.png')} alt="" />
                        </div>
                        <h3>{i18n.language == 'ar' ? 'بناء ثقة قوية مع جمهورك' : 'Ensure sustainable business growth'}</h3>
                    </div>

                    <div className="card" data-aos="zoom-out-up" data-aos-duration="1500">
                        <div className="icon">
                            <img style={{ width: '64px' }} src={require('../assest/benefit4.png')} alt="" />
                        </div>
                        <h3>{i18n.language == 'ar' ? 'تحقيق نمو مستدام لعملك' : 'Ensure Sustainable Business Growth'}</h3>
                    </div>


                </div>
            </div>
        </section>
    )
}
