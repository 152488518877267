import React from 'react'
import Home from '../components/Home'
import Services from '../components/Services'
import Footer from '../components/Footer'
import Customers from '../components/Customers'
import WhyMe from '../components/WhyMe'
import Approach from '../components/Approach'
import Benefit from '../components/Benefit'
import Projects from '../components/Projects'
import Contact from '../components/Contact'

export default function HomePage () {
    return (
        <div>
            <Home />
            <Customers />
            <WhyMe />
            <Approach />
            <Benefit />
            <Services />
            <Projects />
            <Contact />
            <Footer />
        </div>
    )
}
