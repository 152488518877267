import i18next from 'i18next';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects } from '../rtk/slices/projects-slice';

export default function Projects () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18next.on('languageChanged', function (lng) {
            // تحديث اتجاه الصفحة بناءً على اللغة الحالية
            document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        });

        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])

    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects.projects);

    const projects_en = projects.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "img": ser_en.img,
                "name": ser_en.name_en,
                "challenge": ser_en.challenge_en,
                "solution": ser_en.solution_en,
                "result": ser_en.result_en
            })
        }
        return ser_en;
    })


    useEffect(() => {
        // fetch show
        dispatch(fetchProjects());
    }, [])

    return (
        <section className='projects'>
            <div className="container">
                <h2 className="title">
                    {i18n.language == 'ar' ? <p> مشاريع   <span style={{ color: 'var(--five-color)' }}>سابقة</span>؟</p> : <p> Previous <span style={{ color: 'var(--five-color)' }}>Projects</span></p>}
                </h2>

                <div className="cards">
                    {projects_en?.map((project) => {
                        return (
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <img className='imgProject' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${project.img}`} alt={project.name} />

                                <h3>{project.name}</h3>
                                <h4>
                                    {i18n.language == 'ar' ? 'التحدي' : 'Challenge'} <img style={{ width: '25px' }} src={require('../assest/project1.png')} alt="" />
                                    <span>{project.challenge}</span>
                                </h4>

                                <h4>
                                    {i18n.language == 'ar' ? 'الحل' : 'Solution'} <img style={{ width: '25px' }} src={require('../assest/project2.png')} alt="" />
                                    <span>{project.solution}</span>
                                </h4>

                                <h4>
                                    {i18n.language == 'ar' ? 'النتيجة' : 'Result'} <img style={{ width: '25px' }} src={require('../assest/project3.png')} alt="" />
                                    <span>{project.result}</span>
                                </h4>
                            </div>
                        )
                    })}


                </div>
            </div>
        </section>
    )
}
