import { Route, Routes } from "react-router";
import HomePage from "./Pages/HomePage";
import BlogsPage from "./Pages/BlogsPage";
import Navbar from "./components/Navbar";
import ServicePage from "./Pages/ServicePage";
import BlogPage from "./Pages/BlogPage";



function App () {
  return (
    <div className="App">
      <Navbar />

      <Routes>
        {/* routes home */}
        <Route path="/" element={<HomePage />} />
        <Route path="/blogs" element={<BlogsPage />} />

        {/* routes inSite */}
        <Route path="/service/:id" element={<ServicePage />} />
        <Route path="/blogs/blog/:id" element={<BlogPage />} />
      </Routes>
    </div>
  );
}

export default App;
