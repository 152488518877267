import React, { useEffect, useState } from 'react'
import logos from '../assest/logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVisitor } from '../rtk/slices/visitor-slice';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchLogo } from '../rtk/slices/logo-slice';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Navbar () {
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    const visitor = useSelector((state) => state.visitor.visitor);
    const color = useSelector((state) => state.color.color);
    const logo = useSelector((state) => state.logo.logo);

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    window.onscroll = () => {
        const header = document.querySelector("header")
        if (window.scrollY > 20) {
            header.classList.add("active")
        } else {
            header.classList.remove("active")
        }

        const container = document.querySelector(".container")
        const menu = document.querySelector(".menu")
        container.classList.remove("active")
        menu.classList.remove("active")
    }

    setTimeout(() => {
        const container = document.querySelector('.container');
        const header = document.querySelector('header');
        const menu = document.querySelector('.menu');
        menu.addEventListener('click', () => {
            container.classList.toggle("active")
            menu.classList.toggle('active')
            header.classList.add('active')
        })

        const as = document.querySelectorAll(".links li a")
        as.forEach((a) => {
            a.addEventListener("click", () => {
                as.forEach((li) => li.classList.remove("active"))
                a.classList.add("active");
            })
        })
    }, 2000)
    useEffect(() => {
        return () => {
            const container = document.querySelector('.container');
            const menu = document.querySelector('.menu');
            menu.addEventListener('click', () => {
                container.classList.toggle("active")
                menu.classList.toggle('active')
            })

            const links = document.querySelectorAll(".links li a")
            links.forEach((link) => {
                link.addEventListener("click", () => {
                    links.forEach((li) => li.classList.remove("active"))
                    link.classList.add("active");
                })
            })
        }
    }, [])


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }

        // fetch color
        dispatch(fetchColor())
        // fetch logo
        dispatch(fetchLogo())

        // fetch visitor
        dispatch(fetchVisitor())
    }, [])

    if (visitor.length > 0) {
        if (!sessionStorage.getItem('visit')) {
            // add visitor
            const addVisitor = () => {
                fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?visitor=${visitor['0'].total}}`, { method: 'POST' })
            }
            sessionStorage.setItem('visit', 1)
            addVisitor()
        }
    }

    return (
        <header>
            <div className="container">
                <ul className='links'>
                    <li><NavLink to="/" >{i18n.language == "ar" ? "الرئيسية" : "Home"}</NavLink></li>
                    <li><NavLink to="/blogs">{i18n.language == "ar" ? "التدوينات" : "Blogs"}</NavLink></li>
                </ul>
                <NavLink to="/home" className='logo' href="#"><img style={{ width: "65px" }} src={logo.length > 0 && `${process.env.REACT_APP_PROJECTS_API}assest/imgs/${logo[0].logo}`} alt="" /></NavLink>

                {i18n.language == "ar" && <li className='langLi'><button className='language' onClick={() => {
                    i18n.changeLanguage("en");
                    sessionStorage.setItem("lang", "en");
                }}>English</button></li>}
                {i18n.language == "en" && <li className='langLi'><button className='language' onClick={() => {
                    i18n.changeLanguage("ar");
                    sessionStorage.setItem("lang", "ar");
                }}>العربية</button></li>}

                <div className="menu" style={i18n.language == "en" ?
                    { right: "15px", left: "initial" } : { left: "15px", right: "initial" }
                }>
                    <span className='span1'></span>
                    <span className='span2'></span>
                    <span className='span3'></span>
                </div>
            </div>
        </header>
    )
}

export default Navbar
