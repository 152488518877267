import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { fetchServices } from '../rtk/slices/services-slice';


function Services () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18next.on('languageChanged', function (lng) {
            // تحديث اتجاه الصفحة بناءً على اللغة الحالية
            document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        });

        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])

    useEffect(() => {
        const support = document.querySelector('.contact-menu');
        const social = document.querySelector('.contact-menu .main');

        const toggleSocial = () => {
            social.classList.toggle('active');
        };

        support.addEventListener('click', toggleSocial);

        // Cleanup
        return () => {
            support.removeEventListener('click', toggleSocial);
        };
    }, []);

    const dispatch = useDispatch();
    const services = useSelector((state) => state.services.services);

    const services_en = services.map(ser_en => {
        if (i18n.language == "en") {
            return ({
                "id": ser_en.id,
                "img_service": ser_en.img_service,
                "keyword": ser_en.keyword_en,
                "whats_service": ser_en.whats_service_en
            })
        }
        return ser_en;
    })


    useEffect(() => {
        // fetch show
        dispatch(fetchServices());
    }, [])
    return (
        <section className='services' id='services'>
            <div className="container">
                <div className="contact-menu">
                    <div className="main">
                        <div className="up">
                            <a href={'https://www.instagram.com/abbadygram/profilecard/?igsh=MXR5aWxiampxdzYwbQ=='} rel="noopener noreferrer" target='_blank' className="card1">
                                <i className="fa fa-instagram instagram"></i>
                            </a>
                            <a href={'https://www.facebook.com/share/19fzYGGdt1/'} target='_blank' rel="noopener noreferrer" className="card2">
                                <i class="fa fa-facebook facebook"></i>
                            </a>
                        </div>
                        <div className="down">
                            <a href={'https://api.whatsapp.com/send/?phone=201065483898'} target='_blank' rel="noopener noreferrer" className="card3">
                                <i className="fa fa-whatsapp whatsapp"></i>
                            </a>
                        </div>
                    </div>
                    <button target='_blank' className="card4 support-menu">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className='support'
                            viewBox="0 0 512 512"
                        >
                            <path d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z" />
                        </svg>
                    </button>
                </div>

                <h2 className="title">
                    {i18n.language == 'ar' ? <p style={{ color: '#fff !important' }}>خدما<span style={{ color: 'var(--five-color)' }}>تي</span>؟</p> : <p> My <span style={{ color: 'var(--five-color)' }}>Services</span></p>}
                </h2>

                <div className="cards">
                    {services_en?.map((service) => {
                        return (
                            <div className="card" data-aos="fade-up" data-aos-duration="1500">
                                <img className='imgService' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service.img_service}`} alt={service.keyword} />
                                <div className="content">
                                    <h3> {service.keyword}</h3>
                                    <p>{service.whats_service.substr(1, 70)}</p>
                                </div>

                                <Link to={`/service/${service.id}`} className="btnService">{i18n.language == 'ar' ? 'المزيد..' : 'More..'}</Link>
                            </div>
                        )
                    })}


                </div>
            </div>
        </section>
    )
}

export default Services
