import i18next from 'i18next';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export default function WhyMe () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18next.on('languageChanged', function (lng) {
            // تحديث اتجاه الصفحة بناءً على اللغة الحالية
            document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        });

        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])

    return (
        <section className='why-me'>
            <div className="container">
                <h2 className="title" data-aos="fade-up" data-aos-duration="1500">
                    {i18n.language == 'ar' ? <p> لماذا  <span style={{ color: 'var(--five-color)' }}>تختارني</span>؟</p> : <p> Why Choose <span style={{ color: 'var(--five-color)' }}>Me</span>?</p>}
                </h2>

                <div className="cards">
                    <div className="card" data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom" data-aos-duration="1500">
                        <div className="icon">
                            <img src={require('../assest/whyMe1.png')} alt="" />
                        </div>
                        <h3>{i18n.language == 'ar' ? 'خبرة متخصصة' : 'Specialized Expertise'}</h3>
                        <p>{i18n.language == 'ar' ? 'أكثر من أربع سنوات من الخبرة في التسويق بالمحتوى مع علامات تجارية بارزة في مصر والخليج.' : 'With over 4 years of experience in content marketing, I’ve worked with leading brands across Egypt and the Gulf region.'}</p>
                    </div>

                    <div className="card" data-aos="fade-up"
                        data-aos-anchor-placement="center-bottom" data-aos-duration="1500">
                        <div className="icon">
                            <img src={require('../assest/whyMe2.png')} alt="" />
                        </div>
                        <h3>{i18n.language == 'ar' ? 'فهم عميق للسوق' : 'Deep Market Understanding'}</h3>
                        <p>{i18n.language == 'ar' ? 'أدرس احتياجات عملائك بدقة، وأصل إليهم بالطريقة التي تلائمهم.' : ' I take the time to study your audiences needs and deliver tailored strategies that resonate with them.'}</p>
                    </div>

                    <div className="card" data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" data-aos-duration="1800">
                        <div className="icon">
                            <img src={require('../assest/whyMe3.png')} alt="" />
                        </div>
                        <h3>{i18n.language == 'ar' ? 'استراتيجيات مبتكرة' : 'Innovative Strategies'}</h3>
                        <p>{i18n.language == 'ar' ? 'أصمم لكل عميل استراتيجية مخصصة تُميّزه عن المنافسين.' : ' Every strategy is crafted specifically for you, ensuring you stand out in your industry.'}</p>
                    </div>

                    <div className="card" data-aos="fade-up"
                        data-aos-anchor-placement="top-bottom" data-aos-duration="1800">
                        <div className="icon">
                            <img src={require('../assest/whyMe4.png')} alt="" />
                        </div>
                        <h3>{i18n.language == 'ar' ? 'نتائج ملموسة' : 'Tangible Results'}</h3>
                        <p>{i18n.language == 'ar' ? ' سوف ترى تغييرًا حقيقيًا في مبيعاتك وتفاعل جمهورك.' : 'You’ll notice a real change in your sales and customer engagement from the very start.'}</p>
                    </div>


                </div>
            </div>
        </section>
    )
}
