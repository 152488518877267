import i18next from 'i18next';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export default function Approach () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18next.on('languageChanged', function (lng) {
            // تحديث اتجاه الصفحة بناءً على اللغة الحالية
            document.documentElement.setAttribute('dir', lng === 'ar' ? 'rtl' : 'ltr');
        });

        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
    }, [])

    return (
        <section className='approach'>
            <div className="container">
                <div className="content">
                    <div className="box-text">
                        {i18n.language == "ar" ? <h2> ماذا يحدث بعد <span>التعاون</span></h2> : <h2> What Happens After We  <span>Collaborate</span></h2>}

                        <div className="cards">
                            <div className="card" data-aos="fade-up"
                                data-aos-anchor-placement="bottom-bottom" data-aos-duration="1500">
                                <div className="num">1</div>
                                <div className="text">
                                    <h3>{i18n.language == 'ar' ? 'الاستماع لرؤيتك وأهدافك' : 'Understanding Your Vision and Goals'}</h3>
                                    <p>{i18n.language == 'ar' ? 'أبدأ من فهم رؤيتك وأهداف عملك.' : 'Our journey begins by understanding your business objectives and vision.'}</p>
                                </div>
                            </div>

                            <div className="card" data-aos="fade-up"
                                data-aos-anchor-placement="bottom-bottom" data-aos-duration="1500">
                                <div className="num">2</div>
                                <div className="text">
                                    <h3>{i18n.language == 'ar' ? 'تصميم استراتيجية خاصة بك' : 'Creating a Tailored Strategy'}</h3>
                                    <p>{i18n.language == 'ar' ? 'أُعد خطة واضحة ومحددة تلائم نشاطك وجمهورك المستهدف.' : ' I’ll develop a clear, targeted plan that aligns with your business and audience.'}</p>
                                </div>
                            </div>

                            <div className="card" data-aos="fade-up"
                                data-aos-anchor-placement="top-center" data-aos-duration="1500">
                                <div className="num">3</div>
                                <div className="text">
                                    <h3>{i18n.language == 'ar' ? ' التنفيذ والمتابعة' : 'Implementation and Continuous Improvement'}</h3>
                                    <p>{i18n.language == 'ar' ? ' أنفذ الخطة خطوة بخطوة، وأتابع النتائج لتحسين الأداء باستمرار.' : 'I execute the plan step-by-step, monitor results, and refine the approach for maximum impact'}</p>
                                </div>
                            </div>

                            <div className="card" data-aos="fade-up"
                                data-aos-anchor-placement="top-center" data-aos-duration="1000">
                                <div className="num">4</div>
                                <div className="text">
                                    <h3>{i18n.language == 'ar' ? 'الدعم المستمر' : 'Ongoing Support'}</h3>
                                    <p>{i18n.language == 'ar' ? 'أبقى معك في كل مرحلة حتى تحقق النتائج التي تطمح إليها.' : 'I’ll be with you at every stage until you achieve the results you aspire to'}</p>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="box-image">
                        <img data-aos="fade-up"
                            data-aos-anchor-placement="top-center" data-aos-duration="1000" style={{ width: '100%', maxWidth: '30rem' }} src={require('../assest/approach.png')} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}
