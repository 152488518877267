import { configureStore } from "@reduxjs/toolkit"
import customersSlice from "./slices/customers-slice"
import visitorSlice from "./slices/visitor-slice"
import colorSlice from "./slices/color-slice"
import logoSlice from "./slices/logo-slice"
import servicesSlice from "./slices/services-slice"
import projectsSlice from "./slices/projects-slice"
import blogsSlice from "./slices/blogs-slice"



export const store = configureStore({
    reducer: {
        projects: projectsSlice,
        blogs: blogsSlice,
        customers: customersSlice,
        visitor: visitorSlice,
        color: colorSlice,
        logo: logoSlice,
        services: servicesSlice,
    }
})